<template>
  <div></div>
</template>

<script>
export default {
  name: "Selector",
  inject: ['page', 'erplayout'],
  /*watch: {
    '$route' (v){
      this.$uloc.printView.get(window) && this.$uloc.printView.close(this.window)
    }
  },*/
  mounted () {
    const route = this.$route.name
    let component

    switch (route) {
      case 'leiloes.relatorios.resumo':
        component = () => import('@/components/leiloes/components/relatorios/Resumo.vue')
        break
      case 'leiloes.relatorios.saida':
        component = () => import('@/components/leiloes/components/relatorios/Saida.vue')
        break
      case 'leiloes.relatorios.saida.arrematante':
        component = () => import('@/components/leiloes/components/relatorios/SaidaArrematante.vue')
        break
      case 'leiloes.relatorios.lances':
        component = () => import('@/components/leiloes/components/relatorios/Lances.vue')
        break
      case 'leiloes.relatorios.lances.comitente':
        component = () => import('@/components/leiloes/components/relatorios/LancesPorComitente.vue')
        break
      case 'leiloes.relatorios.certidao.publicacao':
        component = () => import('@/components/leiloes/components/relatorios/CertidaoPublicacao.vue')
        break
      case 'leiloes.relatorios.certidao.negativa':
        component = () => import('@/components/leiloes/components/relatorios/CertidaoNegativa.vue')
        break
      case 'leiloes.relatorios.certidao.negativa2':
        component = () => import('@/components/leiloes/components/relatorios/CertidaoNegativa2.vue')
        break
      case 'leiloes.relatorios.numeros.visitas':
        component = () => import('@/components/leiloes/components/relatorios/NumerosVisitas.vue')
        break
      case 'leiloes.relatorios.diario.leilao':
        component = () => import('@/components/leiloes/components/relatorios/DiarioLeilao.vue')
        break
      case 'leiloes.relatorios.diario.leilao-v2':
        component = () => import('@/components/leiloes/components/relatorios/DiarioLeilaoV2.vue')
        break
      case 'leiloes.relatorios.lotes.nao.vendidos':
        component = () => import('@/components/leiloes/components/relatorios/LotesNaoVendidos.vue')
        break
      case 'leiloes.relatorios.lotes.condicional':
        component = () => import('@/components/leiloes/components/relatorios/LotesCondicional.vue')
        break
      case 'leiloes.relatorios.lotes.vendidos':
        component = () => import('@/components/leiloes/components/relatorios/LotesVendidos.vue')
        break
      case 'leiloes.relatorios.lotes.retirados':
        component = () => import('@/components/leiloes/components/relatorios/LotesRetirados.vue')
        break
      case 'leiloes.relatorios.termo.alienacao':
        component = () => import('@/components/leiloes/components/relatorios/TermoAlienacao.vue')
        break
      case 'leiloes.relatorios.arrematacao.leilao':
        component = () => import('@/components/leiloes/components/relatorios/ArrematacaoLeilao.vue')
        break
      case 'leiloes.relatorios.arrematacao.auto2':
        component = () => import('@/components/leiloes/components/relatorios/AutoArrematacao2.vue')
        break
    }

    let window = this.window = route // @TODO: ID do leilao concatenado .{ID} ?
    !this.$uloc.printView.get(window) && this.$uloc.printView.new({
      wid: window,
      title: 'Relatórios do leilão ' + this.page.leilao.codigo,
      windowClass: 'erp-print',
      contentClass: '',
      props: {
        leilao: this.page.leilao
      }
    }, component)
        .then((wid) => {
          this.$uloc.printView.listen(this.window, {
            close: (wid) => {
              this.$router.push({name: 'leiloes.relatorios'})
            }
          })
        }) // return wid
  },
  beforeDestroy() {
    this.$uloc.printView.get(this.window) && this.$uloc.printView.close(this.window)
  }
}
</script>
